import * as Sentry from "@sentry/vue";
export default ({ app }) => {
  app.$auth.options.redirect = {
    login: app.localePath('/'),
    logout: app.localePath('/?logout'),
    callback: app.localePath('login'),
    home: app.localePath('/feature/write-chapter'),
  };

  app.i18n.onLanguageSwitched = () => {
    app.$auth.options.redirect = {
      login: app.localePath('/'),
      logout: app.localePath('/?logout'),
      callback: app.localePath('login'),
      home: app.localePath('/feature/write-chapter'),
    };
  };

  if (app.$auth.loggedIn) {
    Sentry.setUser({ email: app.$auth.user.email, id: app.$auth.user.id });
  }
}
