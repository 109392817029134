import {
  getTemplates,
  sendPrompt,
  sendPromptBatch,
  getPrompts,
} from '@/api/prompt.service'

import {
  getDocuments,
  getDocument,
  deleteDocument,
  // getDocumentMessages
} from '@/api/document.service'

import {
  getArticles,
  deleteArticle,
  // getArticleMessages
} from '@/api/article.service'

import { getPapers, bookmarkPaper, unbookmarkPaper } from '@/api/papers.service'

export const state = () => ({
  templates: null,
  flows: [],
  groups: [],
  projects: [],
  currentProject: null,
  additionalWordsUsed: 0,
  history: {},
  articles: [],
  documents: [],
  papers: [],
  activeHistory: null,
  showCheatSheet: true,
  error: null,
  projectsEnabled: {
    'find-sources': true,
    'write-outline': true,
    'write-chapter': true,
    'write-essay': true,
  },
})

function isProjectSetupComplete(project) {
  return !!project?.id && !!project?.name && !!project?.topic && !!project?.language && !!project?.approach
}

function addProjectSetupCompleteFlag(project) {
  return { ...project, setupComplete: isProjectSetupComplete(project) }
}

export const getters = {
  usedTokens: (state, __, rootState) =>
    rootState.auth.user?.words_used + state.additionalWordsUsed,
  maxTokens: (_, __, rootState) =>
    rootState.auth.user?.words_limit + rootState.auth.user?.words_extra,
  limitTokens: (_, __, rootState) => rootState.auth.user?.words_limit,
  extraTokens: (_, __, rootState) => rootState.auth.user?.words_extra,
  templates: (state) => state.templates,
  flows: (state) => state.flows,
  groups: (state) => state.groups,
  projects: (state) => state.projects,
  currentProject: (state) => state.currentProject,
  cheatSheet: (state) => state.showCheatSheet,
  error: (state) => state.error,
  getHistory: (state) => (slug) => {
    const history = state.history[slug] ?? []
    return [...history].sort(
      (a, b) => new Date(b.created_at) - new Date(a.created_at)
    )
  },
  articles: (state) => state.articles,
  documents: (state) => state.documents,
  papers: (state) => state.papers,
  sources: (state) => {
    const papers =
      state.papers?.map((paper) => ({ ...paper, type: 'paper' })) || []
    const documents =
      state.documents?.map((document) => ({ ...document, type: 'document' })) ||
      []
    const articles =
      state.articles?.map((article) => ({ ...article, type: 'article' })) || []
    return [...papers, ...documents, ...articles]
  },
  activeHistory: (state) => state.activeHistory,
  history: (state) => state.history,
  subscription: (_, __, rootState) => rootState.auth.user?.subscription,
  projectsEnabled: (state) => state.projectsEnabled,
}

export const actions = {
  nuxtServerInit({ commit }) {
    let value = this.$cookies.get('projectsEnabled')
    value = {
      'find-sources': false,
      'write-outline': false,
      'write-chapter': false,
      'write-essay': false,
    }
    if (value === undefined || value === null || typeof value === 'boolean') {
      value = {
        'find-sources': true,
        'write-outline': true,
        'write-chapter': true,
        'write-essay': true,
      }
      this.$cookies.set('projectsEnabled', value)
    }

    commit('SET_PROJECTS_ENABLED', { slug: 'find-sources', value: value['find-sources'] })
    commit('SET_PROJECTS_ENABLED', { slug: 'write-outline', value: value['write-outline'] })
    commit('SET_PROJECTS_ENABLED', { slug: 'write-chapter', value: value['write-chapter'] })
    commit('SET_PROJECTS_ENABLED', { slug: 'write-essay', value: value['write-essay'] })
  },

  toggleProjectsEnabled({ commit, state }, slug) {
    const newValue = !state.projectsEnabled[slug]
    commit('SET_PROJECTS_ENABLED', { slug, value: newValue })
    this.$cookies.set('projectsEnabled', { ...state.projectsEnabled, [slug]: newValue })
  },

  async getTemplates({ commit }) {
    const templates = await this.$api.run(getTemplates).request
    commit('SET_TEMPLATES', templates)
    return { templates }
  },

  fetchProjects({ commit }) {
    return new Promise((resolve, reject) => {
      this.$socket.emit('message', { type: 'fetch_projects' }, (response) => {
        if (response.result === 'not_ok') {
          reject(response)
          return
        }
        commit('SET_PROJECTS', response.data.map(addProjectSetupCompleteFlag))
        const currentProjectID = this.$cookies.get('currentProject')
        if (currentProjectID) {
          const project = response.data.find((p) => p.id === currentProjectID)
          if (project) commit('SET_CURRENT_PROJECT', project)
        }
        resolve(response.data)
      })
    })
  },

  deleteProject({ commit, state }, project) {
    this.$socket.emit(
      'message',
      {
        type: 'delete_project',
        payload: {
          project_id: project.id,
        },
      },
      (res) => {
        if (res.result === 'not_ok') {
          console.log('Error deleting project: ', res)
        }
        const projects = state.projects.filter((p) => p.id !== project.id)
        commit('SET_PROJECTS', projects)
        if (state.currentProject?.id === project.id) {
          commit('SET_CURRENT_PROJECT', null)
        }
      }
    )
  },

  getFlows({ commit }) {
    // Mock data for now
    // flow definition:
    /*
    Object {
      id: string,
      name: string,
      description: string,
      steps: Array<String>, <- slugs of the used templates
      connections: Array<Function<String> -> Map<String, String>>, <- a function of the output from a previous template to a map of the field name(s) into which (a possibly transformed) output from a previous step gets copied into
    }
    */
    const flows = [
      {
        slug: 'summarize',
        headline: this.$i18n.t('flows.summarize.headline'),
        description: this.$i18n.t('flows.summarize.description'),
        steps: ['summarize-text', 'summarize-source'],
        step_titles: [
          this.$i18n.t('flows.titles.summarize_text'),
          this.$i18n.t('flows.titles.summarize_source'),
        ],
        connections: [],
      },
    ]

    commit('SET_FLOWS', flows)
  },

  getGroups({ commit }) {
    // Mock data for now
    const groups = [
      {
        title: 'research',
        slug: 'research',
        items: [
          'flow/summarize',
          'feature/find-topic',
          'feature/find-sources',
          'feature/source-qa',
        ],
      },
      {
        title: 'essay',
        slug: 'essay',
        items: [
          'feature/write-outline',
          'feature/write-chapter',
          'feature/write-essay',
        ],
      },
    ]
    commit('SET_GROUPS', groups)
  },

  loadHistory({ commit, state }, slug) {
    this.$api.run(getPrompts, slug).request.then((prompts) => {
      prompts.forEach((prompt) => {
        const history = state.history[slug] ?? []
        if (!history.some((el) => el.id === prompt.id)) {
          prompt.slug = slug
          commit('ADD_HISTORY', prompt)
        }
      })
    })
  },

  async getDocuments({ commit, dispatch }) {
    const documents = await this.$api.run(getDocuments).request
    commit('SET_DOCUMENTS', documents)
    documents.forEach((document) => {
      if (document.is_usable !== undefined && !document.is_usable) {
        dispatch('pollDocumentStatus', document)
      }
    })
  },

  removeDocument({ commit, state }, document) {
    this.$api.run(deleteDocument, document.uuid).then((res) => {
      if (!res.success) return
      const documents = state.documents.filter((d) => d.id !== document.id)
      commit('SET_DOCUMENTS', documents)
    })
  },

  getArticles({ commit }) {
    this.$api.run(getArticles).then((articles) => {
      commit('SET_ARTICLES', articles)
    })
  },

  removeArticle({ commit, state }, article) {
    this.$api.run(deleteArticle, article.id).then((res) => {
      if (!res.success) return
      const articles = state.articles.filter((a) => a.id !== article.id)
      commit('SET_ARTICLES', articles)
    })
  },

  getPapers({ commit }) {
    this.$api.run(getPapers).then((papers) => {
      commit('SET_PAPERS', papers)
    })
  },

  removePaper({ commit, state }, paper) {
    // return success as Promise
    const id = paper.id
    return new Promise((resolve) => {
      this.$api
        .run(unbookmarkPaper, id)
        .then((res) => {
          if (!res.success) {
            resolve(false)
            return
          }
          const papers = state.papers.filter((p) => p.id !== id)
          commit('SET_PAPERS', papers)
          resolve(true)
        })
        .catch(() => {
          resolve(false)
        })
    })
  },

  addPaper({ commit, state }, paper) {
    // return success as Promise
    const id = paper.id
    return new Promise((resolve) => {
      this.$api
        .run(bookmarkPaper, id)
        .then((res) => {
          if (!res.success) {
            resolve(false)
            return
          }
          const papers = state.papers.filter((p) => p.id !== id)
          papers.push(paper)
          commit('SET_PAPERS', papers)
          resolve(true)
        })
        .catch(() => {
          resolve(false)
        })
    })
  },

  addSource({ commit }, source) {
    if (source.type === 'article') {
      commit('ADD_ARTICLE', source)
    } else if (source.type === 'document') {
      commit('ADD_DOCUMENT', source)
      if (source.is_usable !== undefined && !source.is_usable) {
        this.dispatch('pollDocumentStatus', source)
      }
    }
  },

  setHistoryItem({ commit }, item) {
    if (!item) {
      commit('SET_ACTIVE_HISTORY', null)
    } else {
      commit('SET_ACTIVE_HISTORY', item)
    }
  },

  sendRequest({ commit }, { query, slug, batch }) {
    commit('ERROR', null)
    const url = batch ? sendPromptBatch : sendPrompt
    return this.$api
      .run(url, slug, {
        inputs: query,
      })
      .then((results) => {
        if (!results) {
          commit(
            'ERROR',
            'Etwas ist schief gelaufen. Bitte versuche es erneut.'
          )
          return
        }
        if (!Array.isArray(results)) results = [results]
        for (const result of results) {
          commit('ADD_WORDS_USED', result.words_used)
          commit('ADD_HISTORY', { ...result, slug })
        }
        return results
      })
  },
  deleteResult({ commit }, { slug, result }) {
    commit('DELETE_FROM_HISTORY', { slug, result })
  },
  addWordsUsed({ commit }, wordsUsed) {
    commit('ADD_WORDS_USED', wordsUsed)
  },
  toggleCheatSheet({ state, commit }) {
    commit('SET_CHEATSHEET', !state.showCheatSheet)
  },
  clearError({ commit }) {
    commit('ERROR', null)
  },
  setError({ commit }, error) {
    commit('ERROR', error)
  },

  async pollDocumentStatus({ commit }, document) {
    const POLLING_INTERVAL = 10000 // 10 seconds
    const RETRIES = 100

    const poll = async (tries) => {
      const updatedDocument = await this.$api.run(getDocument, document.uuid)
      commit('UPDATE_DOCUMENT', updatedDocument)
      if (tries <= 0) return
      if (!updatedDocument.is_usable) {
        setTimeout(() => {
          poll(tries - 1)
        }, POLLING_INTERVAL)
      }
    }

    await poll(RETRIES)
  },
}

export const mutations = {
  SET_ACTIVE_HISTORY(state, history) {
    state.activeHistory = JSON.parse(JSON.stringify(history))
  },
  SET_TEMPLATES(state, templates) {
    state.templates = templates
  },
  SET_FLOWS(state, flows) {
    state.flows = flows
  },
  SET_GROUPS(state, groups) {
    state.groups = groups
  },
  SET_PROJECTS(state, projects) {
    state.projects = projects
  },
  SET_CURRENT_PROJECT(state, project) {
    if (typeof project === 'string') {
      project = state.projects.find((p) => p.id === project)
    }
    this.$cookies.set('currentProject', project?.id)
    project = addProjectSetupCompleteFlag(project)
    console.log('store: setting current project: ', project)
    state.currentProject = project
  },
  SET_ARTICLES(state, articles) {
    state.articles = articles
  },
  SET_PAPERS(state, papers) {
    state.papers = papers
  },
  ADD_ARTICLE(state, article) {
    state.articles = [
      ...state.articles.filter((a) => a.uuid !== article.uuid),
      article,
    ]
  },
  SET_DOCUMENTS(state, documents) {
    state.documents = documents
  },
  UPDATE_DOCUMENT(state, updatedDocument) {
    state.documents = [
      ...state.documents.map((document) =>
        document.uuid === updatedDocument.uuid ? updatedDocument : document
      ),
    ]
  },
  ADD_DOCUMENT(state, document) {
    state.documents = [
      ...state.documents.filter((d) => d.uuid !== document.uuid),
      document,
    ]
  },
  ADD_WORDS_USED(state, wordsUsed) {
    state.additionalWordsUsed += wordsUsed
  },
  ADD_HISTORY(state, entry) {
    const slug = entry.slug
    const array = state.history[slug] ?? []
    array.push(entry)
    this._vm.$set(state.history, slug, array)
  },
  DELETE_FROM_HISTORY(state, { slug, result }) {
    const array = state.history[slug]
    _arrayRemove(array, result, (el) => el.id === result.id)
  },
  SET_CHEATSHEET(state, show) {
    state.showCheatSheet = show
  },
  ERROR(state, error) {
    state.error = error
  },
  SET_PROJECTS_ENABLED(state, { slug, value }) {
    state.projectsEnabled[slug] = value
  },
}

function _arrayRemove(array, element, match) {
  if (match === undefined) match = (el) => el === element
  const index = array.findIndex(match)
  if (index > -1) {
    array.splice(index, 1)
  }
}
